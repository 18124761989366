*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

@font-face {
  font-family: "SuperComic";
  src: local("SuperComic"), url("./fonts/SuperComic.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans";
  src: local("OpenSans-Regular"),
    url("./fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Open Sans";
  src: local("penSans-Bold"),
    url("./fonts/OpenSans-Bold.ttf") format("truetype");
  font-weight: 700;
}

:root {
  --ff-primary: "Open Sans", sans-serif;
  --ff-secondary: "SuperComic";
  --fs-h1: 2rem;
  --fs-h2: 1.5rem;
  --fs-h3: 1.25rem;
  --fs-body: 1rem;
  --clr-background: linear-gradient(
    0.77deg,
    rgba(255, 255, 255, 0.8) 0.55%,
    rgba(200, 215, 40, 0.8) 9.16%,
    rgba(226, 26, 230, 0.8) 45.14%,
    rgba(121, 35, 220, 0.8) 78.78%
  );
  --clr-background-lg: linear-gradient(
    0.6deg,
    rgba(255, 255, 255, 0.8) 2%,
    rgba(200, 215, 40, 0.8) 16%,
    rgba(226, 26, 230, 0.8) 45.14%,
    rgba(121, 35, 220, 0.8) 78.78%
  );
  --clr-foreground: #e1efa9;
  --clr-canvas: #ffffff;
  --clr-grey-light: #f1f1f1;
  --clr-grey-medium: #d3d3d3;
  --clr-grey-dark: #7c7c7c;
  --clr-pink-light: #f9d1fa;
  --clr-pink-dark: #e21ae6;
  --clr-purple: #7923dc;
  --clr-active: #262626;
}

html {
  width: 100%;
  height: 100%;
}

body {
  font-family: var(--ff-primary);
  font-weight: 400;
  font-size: var(--fs-body);
  color: var(--clr-foreground);
}

@media (min-width: 1000px) {
  body {
    background: var(--clr-background-lg);
    background-repeat: no-repeat;
    background-size: contain;
  }
}

#root {
  min-height: 100vh;
  position: relative;
}

.ff-comics {
  font-family: var(--ff-secondary);
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.pointer {
  cursor: pointer;
}

div,
img,
button {
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/*  */

.wrapper {
  gap: 16px;
  align-items: center;
  min-height: 100vh;
}

@media (min-width: 900px) {
  .wrapper {
    padding-bottom: 80px;
  }
}

header {
  width: 100%;
  gap: 4px;
  padding: 30px 24px 10px;
  justify-content: space-between;
}

header p {
  padding-bottom: 8px;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

header a {
  color: inherit;
}

.coin-img {
  width: 40px;
  height: 60px;
  margin-bottom: -15px;
  margin-top: -15px;
  background-image: url("./assets/images/coin.gif");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 800px) {
  .coin-img {
    width: 60px;
    height: 80px;
    margin-bottom: -25px;
    margin-top: -25px;
  }
}

@media (max-width: 600px) {
  header h2 {
    font-size: 12px;
  }

  header p {
    font-size: 6px;
  }
}

/*  */

.btn-container {
  justify-content: space-between;
  padding: 14px 16px;
  width: 100%;
  max-width: 1050px;
  gap: 10px;
}

.btn-container > div {
  cursor: pointer;
  align-items: center;
  font-weight: 700;
}

.btn-container > div:first-child {
  position: relative;
}

.btn-container > div > label {
  align-items: center;
}

.btn-options {
  padding: 8px 14px;
  gap: 10px;
  border-radius: 10000px;
  background-color: #4d4d4d;
  color: #fff;
  opacity: 80%;
}

.btn-options.active {
  background-color: #262626;
}

.btn-options .icon-more {
  fill: #fff;
}

.btn-share {
  padding: 8px 14px;
  gap: 10px;
  border-radius: 6px;
  background-color: var(--clr-purple);
}

@media (min-width: 600px) {
  .btn-container {
    justify-content: flex-end;
  }
}

/*  */

@media (max-width: 1000px) {
  .main-container {
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    position: relative;
  }
}

/*  */

.canvas-section {
  gap: 12px;
  padding: 0px 16px;
}

.canvas-section .ff-comics {
  font-size: 12px;
}

.canvas-wrapper {
  position: relative;
  background-color: var(--clr-canvas);
  color: var(--clr-grey-dark);
  border-radius: 14px;
}

.canvas-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: var(--clr-grey-dark);
}

.canvas-btns {
  background-color: #4d4d4d;
  color: #fff;
  opacity: 98%;
  border-radius: 11px;
  padding: 14px 16px;
  font-weight: 700;
  align-self: flex-end;
  gap: 20px;
  cursor: pointer;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 10;
}

.canvas-btns div {
  gap: 10px;
  align-items: center;
}

.canvas-btns svg path {
  fill: #fff;
}

.canvas-btns > div {
  opacity: 80%;
}

.canvas-btns > div:hover {
  opacity: 100%;
}

canvas {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 14px;
  gap: 10px;
}

@media (max-width: 1000px) {
  .canvas-section {
    background: var(--clr-background);
    background-repeat: no-repeat;
    padding-bottom: 8px;
  }

  .canvas-wrapper {
    margin: 0 auto;
  }

  .canvas-btns {
    align-self: center;
  }
}

@media (max-width: 400px) {
  .canvas-btns {
    gap: 15px;
  }
}

/*  */

.control-section {
  gap: 16px;
}

.control-section {
  overflow: hidden;
  background-color: #fff;
}

.control-section::-webkit-scrollbar {
  display: none;
}

.control-section > div {
  gap: 10px;
  font-size: 12px;
  padding: 0px 10px;
}

@media (min-width: 1000px) {
  .control-section {
    width: 500px;
    height: 500px;
  }
}

@media (min-width: 1000px) {
  .control-section {
    border-radius: 14px;
    max-height: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.template-row {
  gap: 5px;
  flex-wrap: wrap;
  max-width: 361px;
  max-height: 400px;
  overflow: hidden;
  overflow-y: scroll;
}

.template-row::-webkit-scrollbar {
  display: none;
}

.template-row > div {
  flex: 1 1 0;
}

input[type="file"] {
  display: none;
}

.stickers {
  gap: 30px;
}

.selectors {
  gap: 30px;
  overflow: hidden;
  overflow-x: scroll;
  font-size: 8px;
  color: #4d4d4d;
  font-weight: 800;
}

.selectors::-webkit-scrollbar {
  display: none;
}

@media (min-width: 900px) {
  .selectors {
    font-size: 12px;
  }

  .meme-item {
    letter-spacing: 1px;
  }
}

.selectors > div {
  gap: 2px;
}

.selectors > div > div > img {
  width: 50px;
  height: 50px;
  cursor: pointer;
  object-fit: contain;
}

.bg-selection {
  gap: 5px;
  width: 100%;
}

.bg-selection div {
  gap: 5px;
  max-width: fit-content;
}

.bg-selection img {
  max-width: 40px;
  max-height: 40px;
  z-index: 5;
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
}

.bg-selection div:last-child {
  padding-left: 12px;
}

.bg-img {
  display: flex;
  justify-content: center;
}

.bg-img > img {
  width: 80px;
  height: 80px;
  border-radius: 14px;
  object-fit: cover;
}

#add-bg svg,
#add-acc svg {
  z-index: 6;
}

#add-acc {
  gap: 10px;
}

.bg-selection .meme-item path {
  fill: var(--clr-grey-medium);
}

.memes-selection .meme-item path {
  fill: #4d4d4d;
}

.meme-item.active path {
  fill: var(--clr-grey-medium);
}

.memes-selection {
  gap: 2px;
  width: 100%;
}

.meme-item:not(.active):nth-child(even) {
  background-color: var(--clr-grey-medium);
}

.meme-item:not(.active):nth-child(odd) {
  background-color: var(--clr-grey-dark);
}

.meme-item {
  position: relative;
  padding: 8px;
  flex: 1 0 0;
  border-radius: 0 400px 400px 0;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.meme-item:not(:first-child):before,
.meme-item:after {
  content: " ";
  position: absolute;
  top: 0;
  width: 80%;
  height: 100%;
  background-color: inherit;
}

.meme-item:before {
  left: -25px;
}

.meme-item:after {
  border-radius: 0 1000px 1000px 0;
  right: -10px;
  z-index: 4;
}

.meme-item:first-child {
  border-radius: 1000px 0 0 1000px;
  padding-left: 20px;
}

.meme-item:first-child:after {
  border-radius: 500px 1000px 1000px 500px;
}

.memes-selection .meme-item img {
  max-width: 40px;
  max-height: 40px;
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  z-index: 5;
}

#add-bg:after,
#add-acc:after {
  right: -12px;
}

#add-bg:before {
  left: -16px;
}

.meme-item p {
  z-index: 5;
}

.meme-item.active {
  background-color: var(--clr-active);
  color: var(--clr-grey-medium);
}

.meme-item:not(:first-child).active img {
  margin-left: 4px;
}

.memes > div:last-of-type {
  flex-wrap: wrap;
  justify-content: center;
}

.memes > div:last-of-type img {
  width: 80px;
  height: 80px;
}

.accessories-btn {
  padding: 8px 16px 8px 14px;
  background-color: var(--clr-active);
  color: var(--clr-grey-medium);
  border-radius: 1000px;
  cursor: pointer;
  align-items: center;
  gap: 4px;
}

@media (max-width: 420px) {
  .accessories-btn p {
    display: none;
  }
}

.stickers-img > img {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

/*  */

.template-row {
  min-width: 100%;
}

.control-section .selectors img {
  width: 30px;
  height: 30px;
}

.control-section .meme-item {
  padding-left: 20px;
}

.control-section .meme-item:after {
  width: 50%;
}

.control-section .meme-item:before {
  left: -10px;
}

.control-section .meme-item:not(:first-child) {
  border-radius: 0px;
}

.control-section .meme-item:last-child {
  border-radius: 0px 10px 10px 0px;
}

.control-section .meme-item:first-child:after {
  border-radius: 0px 1000px 1000px 0px;
  right: -15px;
  width: 60%;
}

@media (max-width: 900px) {
  .stickers {
    justify-content: space-between;
  }

  .control-section .memes {
    max-width: 100%;
    margin-bottom: 80px;
  }

  .template-row {
    padding-bottom: 130px;
  }
}

@media (min-width: 1000px) {
  .template-row,
  .memes {
    max-width: none;
    flex-wrap: wrap;
  }
}

@media (max-width: 600px) {
  .control-section {
    max-height: 450px;
  }

  .template-row > div,
  .stickers-img {
    flex: 1 0 0;
    display: flex;
  }

  .bg-img > img,
  .stickers-img img {
    flex: 1 0 0;
  }
}

.custom-tab {
  text-align: center;
  padding: 50px 24px 16px;
  color: #000;
  justify-content: center;
  gap: 20px;
}

.custom-tab img {
  width: 80px;
  height: 80px;
  border-radius: 14px;
  background-size: contain;
}
